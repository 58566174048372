import { AreaChart, BarChart, DonutChart } from 'components'
import React from 'react'

import { PageSection } from '@/components/pageSection/PageSection'

const USER_GROWTH_DATA: { Month: string; Users: number }[] = [
  { Month: 'Nov 2022', Users: 500 },
  { Month: 'Dec 2022', Users: 600 },
  { Month: 'Jan 2023', Users: 550 },
  { Month: 'Feb 2023', Users: 700 },
  { Month: 'Mar 2023', Users: 800 },
  { Month: 'Apr 2023', Users: 750 },
  { Month: 'May 2023', Users: 900 },
  { Month: 'Jun 2023', Users: 850 },
  { Month: 'Jul 2023', Users: 950 },
  { Month: 'Aug 2023', Users: 1100 },
  { Month: 'Sep 2023', Users: 1050 },
  { Month: 'Oct 2023', Users: 1200 },
]

const UserGrowthChart = () => {
  return (
    <AreaChart
      className="h-96"
      title="User growth"
      data={USER_GROWTH_DATA}
      categories={['Users']}
      index="Month"
      // showLegend={false}
    />
  )
}

const REVENUE_EXPENSES_DATA: { Month: string; Revenue: number; Expenses: number }[] = [
  { Month: 'Nov 2022', Revenue: 500, Expenses: 400 },
  { Month: 'Dec 2022', Revenue: 600, Expenses: 500 },
  { Month: 'Jan 2023', Revenue: 550, Expenses: 450 },
  { Month: 'Feb 2023', Revenue: 700, Expenses: 600 },
  { Month: 'Mar 2023', Revenue: 800, Expenses: 700 },
  { Month: 'Apr 2023', Revenue: 750, Expenses: 650 },
  { Month: 'May 2023', Revenue: 900, Expenses: 800 },
  { Month: 'Jun 2023', Revenue: 850, Expenses: 750 },
  { Month: 'Jul 2023', Revenue: 950, Expenses: 850 },
  { Month: 'Aug 2023', Revenue: 1100, Expenses: 1000 },
  { Month: 'Sep 2023', Revenue: 1050, Expenses: 950 },
  { Month: 'Oct 2023', Revenue: 1200, Expenses: 1100 },
]

const RevenueExpensesChart = () => {
  return (
    <BarChart
      className="h-96"
      title="Revenue vs Expenses"
      data={REVENUE_EXPENSES_DATA}
      categories={['Revenue', 'Expenses']}
      index="Month"
      // showLegend={false}
    />
  )
}

const FEATURES_USAGE_DATA: { name: string; value: number }[] = [
  { name: 'Dashboard', value: 500 },
  { name: 'Settings', value: 600 },
  { name: 'Analytics', value: 550 },
  { name: 'Billing', value: 700 },
  { name: 'Teams', value: 800 },
  { name: 'Reports', value: 750 },
]

const FeatureUsageChart = () => {
  return <DonutChart className="h-96" title="Feature usage" data={FEATURES_USAGE_DATA} category="value" index="name" />
}

/*
    - charts
    User Growth (Area Chart):

    X-Axis: Months (Jan, Feb, Mar...)
    Y-Axis: Number of New Signups
    Trendline showing consistent growth over the past year.

    Revenue vs Expenses (Bar Chart):
    X-Axis: Months
    Y-Axis: Amount (in dollars)
    Two bars side by side for each Month, one representing revenue and the other representing expenses.

    Feature Usage (Pie Chart):
    Segments: Dashboard, Settings, Analytics, Billing, Teams, Reports
    Each segment showing the percentage of Users engaging with that feature.
*/
export const DemoCharts = () => {
  return (
    <PageSection title="Charts" className="border-b-0">
      <UserGrowthChart />

      <RevenueExpensesChart />

      <FeatureUsageChart />
    </PageSection>
  )
}
