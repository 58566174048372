import { colors } from 'colors'
import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Variant = 'default' | 'inverted'

export type Color = typeof colors[number]

const variantToClassNames: Record<Variant, string> = {
  default:
    'from-theme-brand-subtle dark:from-dark-theme-brand-subtle to-theme-brand-emphasis dark:to-dark-theme-brand-emphasis',
  inverted: 'from-theme-brand-muted dark:from-dark-theme-brand-muted to-theme-background dark:to-dark-theme-background',
}

const getColorClassNames = (variant: Variant, color?: Color): string => {
  if (color) {
    if (variant === 'default') {
      return `from-${color}-400 dark:from-${color}-800 to-${color}-700 dark:to-${color}-400`
    }

    // NOTE: we do not support inverted custom colors for now
  }

  return variantToClassNames[variant]
}

type Props = ComponentPropsWithoutRef<'div'> & {
  variant?: Variant
  color?: Color
}

export const Background = ({ className = '', variant = 'default', color, children, ...props }: Props) => {
  return (
    <div
      className={twMerge(
        'bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]',
        getColorClassNames(variant, color),
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
