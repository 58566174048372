import React from 'react'

import { Header } from '@/components/header/Header'
import { PageLayout } from '@/components/pageLayout/PageLayout'

import { Demo } from './components/demo/Demo'

export const Dashboard = () => {
  return (
    <>
      <Header className="lg:hidden" />

      <PageLayout>
        <Demo />
      </PageLayout>
    </>
  )
}
