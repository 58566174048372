import React from 'react'

import { DemoCharts } from './DemoCharts'
import { DemoStats } from './DemoStats'
import { DemoTable } from './DemoTable'

export const Demo = () => {
  return (
    <>
      <DemoStats />

      <DemoTable />

      <DemoCharts />
    </>
  )
}
