import { Button, Table } from 'components'
import React from 'react'

import { PageSection } from '@/components/pageSection/PageSection'

const HEADERS: string[] = ['User name', 'Last activity', 'Number of sessions', 'Feature usage', '']

const DATA: string[][] = [
  ['Emily123', '3 hours ago', '8', 'Dashboard, Settings'],
  ['AlexStartup', '1 day ago', '12', 'Analytics, Billing'],
  ['Sarah_Innovate', '2 days ago', '6', 'Teams, Reports'],
]

export const DemoTable = () => {
  return (
    <PageSection title="User engagement">
      <Table>
        <Table.Head>
          <Table.Row>
            {HEADERS.map((header, index) => (
              <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {DATA.map((row, index) => (
            <Table.Row key={index}>
              {row.map((cell, index) => (
                <Table.Cell key={index}>{cell}</Table.Cell>
              ))}

              <Table.Cell>
                <Button variant="light">View</Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </PageSection>
  )
}
