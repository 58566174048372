import { Badge, Card, Columns, SmallText, TitleText } from 'components'
import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { PageSection } from '@/components/pageSection/PageSection'

const STATS: StatType[] = [
  {
    title: 'Total users',
    value: '1,237',
  },
  {
    title: 'Active users',
    subtitle: '30 days',
    value: 892,
  },
  {
    title: 'Conversion rate',
    value: '14.5%',
  },
  {
    title: 'Monthly revenue',
    value: '$56,780',
  },
  {
    title: 'Churn rate',
    value: '7%',
  },
]

export type StatType = {
  title: string
  subtitle?: string
  value: string | number
}

type StatProps = ComponentPropsWithoutRef<'div'> & StatType

const Stat = ({ className = '', title, subtitle, value, ...props }: StatProps) => {
  return (
    <Card className={twMerge('w-auto', className)} {...props}>
      <div className="mb-2 flex items-center gap-x-2">
        <SmallText>{title}</SmallText>

        {subtitle && <Badge>{subtitle}</Badge>}
      </div>

      <TitleText>{value}</TitleText>
    </Card>
  )
}

export const DemoStats = () => {
  return (
    <PageSection title="Stats" className="pt-0 lg:pt-0">
      <Columns className="">
        {STATS.map(stats => (
          <Stat key={stats.title} {...stats} />
        ))}
      </Columns>
    </PageSection>
  )
}
